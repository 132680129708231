import React from "react"
import { Link } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'

const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `20rem`
})

const Header = styled(`header`)({
  marginBottom: `1rem`
})

const ThankYou = ({ data, location }) => {
  return (
    <React.Fragment>
      {/*<SEO title="All events" />*/}
      <Article>
        <Header>
          <h3>
            {`THANK YOU`}
          </h3>
        </Header>
        <section>
          <p>Your newsletter subscription has been received.</p>
        </section>
      </Article>
    </React.Fragment>
  )
}

export default ThankYou
